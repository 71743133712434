import { State } from '../../../interfaces/State';
import { StreakInfoState, StreakInfoType } from '../../reducers/streaks/types';
import { DAILY_XP_GOAL } from '../../reducers/streaks/v1/streakInfo';

export const selectStreakInfoReady = (state: State): boolean => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  return streakInfo.type === StreakInfoType.Known;
};

export const selectStreakDailyXp = (state: State): number | undefined => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return undefined;
  }

  return streakInfo.dailyXp;
};

export const selectStreakDays = (state: State): number | undefined => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return undefined;
  }

  if (streakInfo.version === 1) {
    return streakInfo.lengthInDays;
  }

  return streakInfo.current.streak.days;
};

export const selectStreakDailyGoalMet = (state: State): boolean => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return false;
  }

  if (streakInfo.version === 1) {
    return streakInfo.dailyXp >= DAILY_XP_GOAL;
  }

  return streakInfo.current.streak_goal_met;
};

export const selectStreakIncrementedAt = (state: State): string | undefined => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return undefined;
  }

  if (streakInfo.version === 1) {
    return streakInfo.incrementedAt;
  }

  return streakInfo.current.streak.last_incremented_at;
};

export const selectStreakDeadline = (state: State): number | undefined => {
  const streakInfo: StreakInfoState = state.get('streakInfo');

  if (streakInfo.type === StreakInfoType.Unknown) {
    return undefined;
  }

  if (streakInfo.version === 1) {
    return streakInfo.deadline;
  }

  return Date.parse(streakInfo.current.streak_deadline);
};
